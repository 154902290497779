import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Row, Column, Hr, H1 } from '../components/Layout';
import Seo from '../components/Seo';
export const _frontmatter = {
  "title": "Imprint",
  "path": "/imprint"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Seo title="Impint - NAICE" mdxType="Seo" />
    <H1 my="30px" mdxType="H1">Imprint</H1>
    <Row mdxType="Row">
      <Column maxWidth="700px" mdxType="Column">
        <h3>{`Datenschutzerklärung`}</h3>
        <p>Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).</p>
        <p>{`Verantwortlicher`}</p>
        <p>{`NAICE architects`}</p>
        <p>{`Schroedter & Hoffmann PartG mbB`}</p>
        <p>{`Cranachstraße 54`}</p>
        <p>{`12157 Berlin`}</p>
        <p>{`E-Mailadresse:`}<a parentName="p" {...{
            "href": "mailto:info@naice.one"
          }}>{`info@naice.one`}</a></p>
        <p>{`Geschäftsführer, Verantwortlicher: Simon Schroedter & Wilko Hoffmann`}</p>
        <p><a parentName="p" {...{
            "href": "http://naice.one/imprint"
          }}>{`http://naice.one/imprint`}</a></p>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column mt="20px" maxWidth="700px" mdxType="Column">
        <h3>{`Webdesign`}</h3>
        <p>{`Diese Seite  wurde konzipiert, gestaltet und umgesetzt von:`}</p>
        <a href="https://bauheaven.com">Bauheaven.com</a>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      